import React from 'react'
import SEO from '../../components/App/SEO';
import Layout from '../../components/App/Layout';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from 'gatsby';
const img = '/img/corporate/management-dashboard.png'

const managementDashBoardPage = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
        <SEO 
        postTitle='Corporate Management Dashboard | BlueMail App' 
        postDescription='Corporate Management Dashboard Whether you need to manage the family business, a team of a few dozen or an entire corporation of hundreds and thousands, BlueMail Desktop Dashboard for managers is what your team needs to quickly and effortlessly handle your...'
        postImage={img}
        postURL='corporate/management-dashboard'
        postSEO
        />
        <Navbar />
        <div className="management-dashboard-area container pt-120 pb-100">
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center'}}>
                    <img src={img} alt='BlueMail Corporate Management Dashboard' style={{width: '100%'}}/>
                </div>
                </div>
                <div className='row'>
                    <div className='col-12' style={{textAlign: 'center', marginTop: '1.8em'}}>
                        <h1>Corporate Management Dashboard</h1>
                        <hr />
                    </div>
                    <div className='col-12'>
                    <p className="mt-30 center-text">
                    Whether you need to manage the family business, a team of a few dozen or an entire corporation of hundreds and thousands, BlueMail Desktop Dashboard for managers is what your team needs to quickly and effortlessly handle your corporate email. With Dashboard, managers can easily create user profiles, modify your Corporation’s Cluster images, and enforce company security policies. With a birdseye view of your operation, corporate email has never been more efficient.        
                    </p>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
)

export default managementDashBoardPage

export const query = graphql`
query ManagementDashBoardPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`